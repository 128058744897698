.qr-code-card {
    width: 300px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .qr-code-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .brand-name {
    margin: 0;
  }
  
  .qr-code-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .qr-code input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .qr-code img {
    max-width: 100%;
  }
  
  .qr-code-footer {
    margin-top: 20px;
    text-align: center;
  }
  
  .divider {
    width: 80%;
    margin: 10px auto;
  }
  
  .support-contact p {
    margin: 5px 0;
  }
  
  .brand-logo,
  .country-logo {
    height: 30px;
  }
  
  .brand-logo,
  .country-logo {
    display: block;
  }
  
  .brand-logo {
    float: left;
  }
  
  .country-logo {
    float: right;
  }
  